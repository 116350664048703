export default class QCSystemLinkHelper
{
  async isSystemLinked ()
  {
    try
    {
      let response = await fetch(process.env.VUE_APP_BACKEND_ENDPOINT
        + 'system/qc/system/linked',
        {
          credentials: 'include',
          method: 'GET'
        }
      );

      let result = await response.json();
      console.log("QCSystemLinkHelper.isSystemLinked() result: ", result);

      return result.connected;
    }
    catch (e)
    {
      console.log("QCSystemLinkHelper.isSystemLinked() error: ", e.stack || e);
      return false;
    }
  }
}